<template>
    <div class="addbank">
        <ul class="addbank-wrap">
            <li>
                <p>开户姓名</p>
                <input type="text" placeholder="请输入银行卡开户姓名" v-model="bank_username" />
            </li>
            <li>
                <p>手机号码</p>
                <input type="number" placeholder="请输入银行预留手机号" v-model="bank_userphone" />
            </li>
            <li>
                <span class="phone">开户银行</span>
                <div class="list">
                    <el-select v-model="bank_name" clearable placeholder="请选择开户银行" @change="changeProduct">
                        <el-option v-for="item in bankLists" :key="item.code" :label="item.name" :value="item">
                        </el-option>
                    </el-select>
                </div>
            </li>
            <li>
                <p>银行卡号</p>
                <input type="number" placeholder="请输入银行卡号" v-model="bank_card" />
            </li>
        </ul>
        <div class="addbank-store">
            <p @click="userAddBankCard">
                <router-link to>保存</router-link>
            </p>
        </div>
        <div class="addbank-footer">
            <p>备注：提现将手动打款至您添加的银行账户内，请确保账号的准确，谢谢！</p>
        </div>
    </div>
</template>
<script>
    import {
        userAddBankCard,
        getBankLists
    } from "@/http/api";
    export default {
        data() {
            return {
                userToken: "",
                bankLists: [{
                        code: "BOC",
                        name: "中国银行"
                    },
                    {
                        code: "ICBC",
                        name: "中国工商银行"
                    },
                    {
                        code: "ABC",
                        name: "中国农业银行"
                    },
                    {
                        code: "CBC",
                        name: "中国建设银行"
                    },
                    {
                        code: "CCB",
                        name: "交通银行"
                    },
                    {
                        code: "PSBC",
                        name: "中国邮政储蓄银行"
                    },
                    {
                        code: "CMB",
                        name: "招商银行"
                    },
                    {
                        code: "CIB",
                        name: "兴业银行"
                    },
                    {
                        code: "CMBC",
                        name: "民生银行"
                    },
                    {
                        code: "CITIC",
                        name: "中信银行"
                    },
                    {
                        code: "SPDB",
                        name: "浦发银行"
                    },
                    {
                        code: "CEB",
                        name: "光大银行"
                    }
                ],

                bank_username: "",
                bank_userphone: "",
                bank_card: "",
                bank_code: "",
                bank_name: "",
                isPost: false
            };
        },
        mounted() {
            this.getBankLists();
            this.userToken = this.$LStorage.getItem("userToken").user_token;
        },
        methods: {
            //   获取银行卡配置信息
            async getBankLists() {
                let res = await getBankLists({});
                // console.log(res);
                if (res.code == 200) {
                    this.bankLists = res.data;
                }
            },

            //添加银行卡
            async userAddBankCard() {
                if (this.isPost) {
                    this.$toast.fail("请勿重复提交");
                } else {
                    if (this.bank_username.trim().length == 0) {
                        this.$toast("请正确填写信息");
                        return true;
                    }
                    if (!/^1[3456789]\d{9}$/.test(this.bank_userphone)) {
                        this.$toast("请正确填写信息");
                        return true;
                    }
                    if (this.bank_code == '') {
                        this.$toast("请选择开户银行");
                        return true;
                    }
                    this.isPost = true;
                    let res = await userAddBankCard({
                        data: {
                            user_token: this.userToken,
                            bank_username: this.bank_username,
                            bank_userphone: this.bank_userphone,
                            bank_card: this.bank_card,
                            bank_code: this.bank_code,
                            bank_name: this.bank_name
                        }
                    });
                    this.isPost = false;
                    if (res.code == 200) {
                        this.$toast.success("添加成功");
                        this.$router.go(-1);
                    } else {
                        this.$toast.fail(res.msgs);
                    }
                }
            },

            //改变的时候获取value
            changeProduct(event) {
                console.log(event)
                this.bank_code = event.code;
                this.bank_name = event.name;
            }

        }
    };
</script>

<style lang="scss" scoped>
    .addbank {
        width: 100%;
        height: 100%;

        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .addbank-wrap {
            width: 7.05rem;
            margin: 0 auto;

            li {
                padding-top: 0.63rem;
                padding-bottom: 0.2rem;
                box-sizing: border-box;
                display: flex;
                border-bottom: 1px solid #f2f2f2;

                .phone {
                    display: block;
                    margin-top: 0.5rem;
                    font-size: 0.26rem;
                    color: #000022;
                }

                p:nth-child(1) {
                    font-size: 0.27rem;
                    color: #000022;
                    margin-right: 0.9rem;
                    margin-top: 0.1rem;
                }

                input {
                    height: 0.5rem;
                    font-size: 0.28rem;
                    color: #7c7c7c;
                }

                .list {
                    display: flex;
                    margin-left: 0.76rem;
                    margin-top: 0.3rem;

                    p {
                        margin-top: -0.5rem;
                        margin-left: 0.2rem;
                    }

                    .el-input__inner {
                        border: none !important;
                    }

                    .el-select .el-input .el-input__inner {
                        font-size: 0.21rem !important;
                        border: none !important;
                    }
                }
            }

            li:nth-child(3) {
                height: 1.06rem;
                padding-top: 0;
                padding-bottom: 0;

                select {
                    font-size: 0.26rem;
                    color: #7c7c7c;

                    option {
                        color: #7c7c7c;
                    }
                }
            }
        }

        .addbank-store {
            width: calc(100% - 0.48rem);
            height: 0.84rem;
            background: #ff5400;
            color: #fff;
            text-align: center;
            line-height: 0.84rem;
            font-size: 0.28rem;
            margin: 0 auto;
            margin-top: 2.16rem;
            border-radius: 0.38rem;
        }

        .addbank-footer {
            width: 6.45rem;
            font-size: 0.19rem;
            color: #494949;
            margin-top: 0.52rem;
            margin-left: 0.56rem;
        }
    }
</style>
